<script>
import ServersSideBar from "@/components/sidebar/servers-sidebar.vue";
import BotSideBar from "@/components/sidebar/bot-sidebar.vue";
import NavBar from "@/components//navbar/navbar.vue";
export default {
    components: {
        ServersSideBar,
        BotSideBar,
        NavBar,
    },
    async mounted() {
        var ctx = this;
        document.title = "DistroyMe | Servers";
        await ctx.me();
    },
};
</script>

<style>
@import url("../assets/scss/servers/main.css");
</style>

<template>
    <ServersSideBar></ServersSideBar>
    <BotSideBar></BotSideBar>
    <NavBar></NavBar>
    <section class="servers opacity">
        <div class="discord">
            <div class="channel">
                <div class="name">Discord Community</div>
                <div class="list">
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                    <div class="item">#channel</div>
                </div>
            </div>
            <div class="chat">
                <div class="name">#General</div>
                <div class="list">
                    <div class="item">
                        <div class="left">
                            <div class="avatar">
                                <img
                                    src="https://cdn.discordapp.com/avatars/623598087688028190/a_dc7f5aa631857c9cd80a341545aebf9e.gif?size=128"
                                />
                            </div>
                        </div>
                        <div class="right">
							<div class="username">AlexM</div>
                            <div class="content">
                                Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel Sois tu es pas sur le serveur sois tu as pas la
                                permission d'écrire dans le channel
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="member">
                <div class="title">Membres</div>
                <div class="list">
                    <div class="role">
                        Administrateur -- 1
                    </div>
                    <div class="profil">
                        <div class="avatar">
                            <img src="https://cdn.discordapp.com/avatars/587927118403469322/0192d46adfa966357ee4a90d15819d48.webp?size=128">
                        </div>
                        <div class="name" title="Jordan El Nino">Jordan El Nino</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>